import React, {useCallback, useEffect, useState} from 'react';
import {withStyle} from 'baseui';
import {Col as Column, Grid, Row as Rows} from 'components/FlexBox/FlexBox';
import {useDrawerDispatch} from 'context/DrawerContext';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Checkbox from 'components/CheckBox/CheckBox';
import {Header, Heading, Wrapper} from 'components/Wrapper.style';
import {StyledCell, StyledHeadCell, StyledTable, TableWrapper,} from './Notification.style';
import {Plus} from 'assets/icons/Plus';
import * as icons from 'assets/icons/category-icons';
import {client} from "../../api/client";
import {BASE_API_URL} from "../../api/endpoint";


const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));

export default function NotificationManage() {
    const [search, setSearch] = useState('');
    const dispatch = useDrawerDispatch();
    const [checkedId, setCheckedId] = useState([]);
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState([]);
    const openDrawer = useCallback(
        () => dispatch({type: 'OPEN_DRAWER', drawerComponent: 'NOTIFICATION_FORM'}),
        [dispatch]
    );

    async function fetchShop() {
        const res = await client.get(`${BASE_API_URL}/notifications`);
        if (res.status !== 200) {
            return
        }
        setData(res.data)
    }

    useEffect(() => {
        fetchShop();
    }, []);


    function handleSearch(event) {
        const value = event.currentTarget.value;
    }

    function onAllCheck(event) {
        if (event.target.checked) {
            const idx = data && data.map((current) => current.id);
            setCheckedId(idx);
        } else {
            setCheckedId([]);
        }
        setChecked(event.target.checked);
    }

    function handleCheckbox(event) {
        const {name} = event.currentTarget;
        if (!checkedId.includes(name)) {
            setCheckedId((prevState) => [...prevState, name]);
        } else {
            setCheckedId((prevState) => prevState.filter((id) => id !== name));
        }
    }

    const Icon = ({name}) => {
        const TagName = icons[name];
        return !!TagName ? <TagName/> : <p>Invalid icon {name}</p>;
    };

    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Header
                        style={{
                            marginBottom: 30,
                            boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
                        }}
                    >
                        <Col md={2}>
                            <Heading>Notification manage</Heading>
                        </Col>

                        <Col md={10}>
                            <Row>
                                <Col md={5} lg={6}>
                                    <Input
                                        value={search}
                                        placeholder="Ex: Search By Name"
                                        onChange={handleSearch}
                                        clearable
                                    />
                                </Col>

                                <Col md={4} lg={3}>
                                    <Button
                                        onClick={openDrawer}
                                        startEnhancer={() => <Plus/>}
                                        overrides={{
                                            BaseButton: {
                                                style: () => ({
                                                    width: '100%',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }),
                                            },
                                        }}
                                    >
                                        Send notification
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Header>

                    <Wrapper style={{boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)'}}>
                        <TableWrapper>
                            <StyledTable
                                $gridTemplateColumns="minmax(70px,70px) auto auto auto auto">
                                <StyledHeadCell>
                                    <Checkbox
                                        type="checkbox"
                                        value="checkAll"
                                        checked={checked}
                                        onChange={onAllCheck}
                                        overrides={{
                                            Checkmark: {
                                                style: {
                                                    borderTopWidth: '2px',
                                                    borderRightWidth: '2px',
                                                    borderBottomWidth: '2px',
                                                    borderLeftWidth: '2px',
                                                    borderTopLeftRadius: '4px',
                                                    borderTopRightRadius: '4px',
                                                    borderBottomRightRadius: '4px',
                                                    borderBottomLeftRadius: '4px',
                                                },
                                            },
                                        }}
                                    />
                                </StyledHeadCell>
                                <StyledHeadCell>Title</StyledHeadCell>
                                <StyledHeadCell>Content</StyledHeadCell>
                                <StyledHeadCell>Link</StyledHeadCell>
                                <StyledHeadCell>Send to</StyledHeadCell>

                                {data ? (
                                    data.length ? (
                                        data
                                            .map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <StyledCell>
                                                        <Checkbox
                                                            name={item.id}
                                                            checked={checkedId.includes(item.id)}
                                                            onChange={handleCheckbox}
                                                            overrides={{
                                                                Checkmark: {
                                                                    style: {
                                                                        borderTopWidth: '2px',
                                                                        borderRightWidth: '2px',
                                                                        borderBottomWidth: '2px',
                                                                        borderLeftWidth: '2px',
                                                                        borderTopLeftRadius: '4px',
                                                                        borderTopRightRadius: '4px',
                                                                        borderBottomRightRadius: '4px',
                                                                        borderBottomLeftRadius: '4px',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </StyledCell>
                                                    <StyledCell>{item.title}</StyledCell>
                                                    <StyledCell>{item.content}</StyledCell>
                                                    <StyledCell>{item.target_link}</StyledCell>
                                                    <StyledCell>{item.send_to}</StyledCell>
                                                </React.Fragment>
                                            ))
                                    ) : (<></>)
                                ) : null}
                            </StyledTable>
                        </TableWrapper>
                    </Wrapper>
                </Col>
            </Row>
        </Grid>
    );
}
