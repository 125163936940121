import React, {useEffect, useState} from 'react';
import {client, setToken} from 'api/client';
import {Endpoint} from 'api/endpoint';

type AuthProps = {
    isAuthenticated: boolean;
    authenticate: Function;
    signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {

    const token = localStorage.getItem('token');
    const [isAuthenticated, makeAuthenticated] = useState(token ? true : false);

    useEffect(() => {
        const checktoken = async () => {
            const user = await client.get(Endpoint.GET_USER_INFOR);
            if (user.status == 200) {
                makeAuthenticated(true)
            } else {
                localStorage.removeItem('token');
                makeAuthenticated(false)
            }
        }

        if (token) {
            checktoken();
        }
    }, [])

    async function authenticate(data, cb) {
        const result = await client
            .post(`${Endpoint.LOGIN}`, JSON.stringify(data))

        if (result.status === 200) {
            makeAuthenticated(true);
            localStorage.setItem('token', result.data.token);
            setToken(result.data.token)
        }

        setTimeout(cb, 100); // fake async
    }

    function signout(cb) {
        makeAuthenticated(false);
        localStorage.removeItem('token');
        setTimeout(cb, 100);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                authenticate,
                signout,
            }}
        >
            <>{props.children}</>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
